<template>
    <div class="print-body">
        <table width="100%" border="1" cellspacing="0">
            <tr style="height: 30px">
                <td colspan="6">
                    <div class="print-title">
                        发货通知单
                    </div>
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: right" colspan="6">
                    编号：<span style="color:red;">{{shippingForm.number}}</span>
                </td>
            </tr>
            <tr style="height: 30px">
                <td rowspan="11" width="5%">
                    发货信息申请表
                </td>
                <td style="text-align: left" width="90px">
                    客户名称：
                </td>
                <td style="text-align: left">
                    {{shippingForm.companyName}}
                </td>
                <td style="text-align: left" width="90px">
                    项目名称：
                </td>
                <td style="text-align: left; width: 30%" colspan="2">
                    {{shippingForm.projectName}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    合同号：
                </td>
                <td style="text-align: left">
                    {{shippingForm.contractNum}}
                </td>
                <td style="text-align: left" width="90px">
                    销售人员：
                </td>
                <td style="text-align: left" colspan="2">
                    {{shippingForm.salesName}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    产品名称：
                </td>
                <td style="text-align: left" colspan="2">
                    塔式起重机安全监控管理系统
                </td>
                <td style="text-align: left" width="15%">
                    订单数量：
                </td>
                <td style="text-align: left">
                    {{shippingForm.towerCount}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    产品名称：
                </td>
                <td style="text-align: left" colspan="2">
                    施工升降机安全监控管理系统
                </td>
                <td style="text-align: left" width="17%">
                    订单数量：
                </td>
                <td style="text-align: left">
                    {{shippingForm.lifterCount}}
                </td>
            </tr>
            <tr style="height: 30px">
                <td style="text-align: left" width="90px">
                    联系电话：
                </td>
                <td style="text-align: left">
                    {{shippingForm.receivingUserTel}}
                </td>
                <td style="text-align: left" width="90px">
                    收货人：
                </td>
                <td style="text-align: left" colspan="2">
                    {{shippingForm.receivingUser}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="15%">
                    送货地址：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.receivingAddress}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    收费政策：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.chargePolicy}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    销售备注：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.remark}}
                </td>
            </tr>
            <tr style="height: 60px">
                <td style="text-align: left" width="90px">
                    审批备注：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.remarkListStr}}
                </td>
            </tr>
            <tr style="height: 230px">
                <td style="text-align: left" width="17%">
                    设备信息清单：
                </td>
                <td style="text-align: left" colspan="4">
                    <div style="width: 100%;font-size: 10px">
                        <table width="100%">
                            <tr>
                                <td style="padding: 0" width="100px">规格型号：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.sizeModel}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">现场编码：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.siteNum}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">钢丝直径(mm)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.stringDiameter}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">前臂长度(m)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.armLength}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">最大载重(T)：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.maxLoad}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">类型：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.deviceType}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">是否需要视频流量卡：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.isNeedCard==1?'是':'否'}}
                                </td>
                            </tr>
                            <tr>
                                <td style="padding: 0" width="100px">是否需要现场调试多塔防碰撞：</td>
                                <td style="padding: 0" :key="i" v-for="(item,i) in tableData">
                                    {{item.isNeedModel==1?'是':'否'}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!--                    {{shippingForm.deviceListStr}}-->
                </td>
            </tr>
            <tr style="height: 300px">
                <td style="text-align: left" width="90px">
                    部件清单：
                </td>
                <td style="text-align: left" colspan="4">
                    {{shippingForm.componentFunList}}
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    var qs = require('qs');
    export default {
        data() {
            return {
                tableData: [],
                remarkList: [],
                shippingForm: {
                    number: '1111111',
                    companyName: '1111111',
                    projectName: '1111111',
                    contractNum: '1111111',
                    salesName: '1111111',
                    towerCount: '1111111',
                    lifterCount: '1111111',
                    receivingUser: '1111111',
                    receivingAddress: '1111111',
                    receivingUserTel: '1111111',
                    deviceListStr: '1111111',
                    towerFunList: '',
                    lifterFunList: '',
                    componentFunList:'',
                    chargePolicy: '',
                    remark: '',
                    remarkListStr: '',
                }
            }
        },
        mounted() {
            window.print()
        },
        created() {
            var dataObj = qs.parse(localStorage.getItem("shippingData"))
            this.shippingForm = {
                number: dataObj.sendNoticeNum,
                companyName: dataObj.companyName,
                projectName: dataObj.projectName,
                contractNum: dataObj.contractNum,
                salesName: dataObj.userName,
                towerCount: dataObj.towerCount,
                lifterCount: dataObj.lifterCount,
                receivingUser: dataObj.receivingUser,
                receivingAddress: dataObj.receivingAddress,
                receivingUserTel: dataObj.receivingUserTel,
                deviceListStr: dataObj.deviceListStr,
                chargePolicy: dataObj.chargePolicy,
                remark: dataObj.remark,
                remarkListStr: ''
            }
            this.tableData = this.shippingForm.deviceListStr;
            var towerItemsStr = dataObj.towerFunList;
            if (towerItemsStr) {
                var towerStr = '塔机：'
                for (var i in towerItemsStr) {
                    towerStr = towerStr + i + ':' + towerItemsStr[i] + ' ; '
                }
                this.shippingForm.towerFunList = towerStr;
                if (towerStr.match('0:0')) {
                    towerStr = ''
                }
            }
            var lifterItemsStr = dataObj.lifterFunList;
            if (lifterItemsStr) {
                var lifterStr = '升降机：'
                for (var i in lifterItemsStr) {
                    lifterStr = lifterStr + i + ':' + lifterItemsStr[i] + ' ; '
                }
                if (lifterStr.match('0:0')) {
                    lifterStr = ''
                }
                this.shippingForm.lifterFunList = lifterStr;
            }
            var componentItemsStr = dataObj.componentFunList;
            if (componentItemsStr) {
                var componentStr = '部件：'
                for (var i in componentItemsStr) {
                    componentStr = componentStr + i + ':' + componentItemsStr[i] + ' ; '
                }
                if (componentStr.match('0:0')) {
                    componentStr = ''
                }
                this.shippingForm.componentFunList = componentStr;
            }
            this.remarkList = dataObj.remarkList;
            var remarkListStr = '';
            for (var i = 0; i < this.remarkList.length; i++) {
                if(this.remarkList[i].auditRemark == '同意'){
                    continue;
                }else {
                    remarkListStr = remarkListStr + this.remarkList[i].auditRemark + '；'
                }
            }
            if ( remarkListStr == ''){
                remarkListStr = '无'
            }
            this.shippingForm.remarkListStr = remarkListStr;
        }

    }
</script>

<style lang="scss" scoped>
    .print-body {
        text-align: center;
        margin: 0 auto;
        width: 715px;
        height: 1123px
    }

    td {
        padding: 5px;
    }

    .print-title {
        font-size: 26px;
        font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
        font-weight: bold;
    }
</style>